const SET_PAGE = 'SET_PAGE';
const SET_PAGE_SIZE = 'SET_PAGE_SIZE';
const SET_PAGINATION = 'SET_PAGINATION';
const RESET_PAGINATION = 'RESET_PAGINATION';

export const setPage = (paginationKey: string, pageNumber: number) => ({type: SET_PAGE, pageNumber, paginationKey});
export const setPageSize = (paginationKey: string, pageSize: number) => ({type: SET_PAGE_SIZE, pageSize, paginationKey});
export const setPagination = (paginationKey: string, config: PageableRequest) => ({type: SET_PAGINATION, pagination: config, paginationKey});
export const resetPagination = (paginationKey: string) => ({type: RESET_PAGINATION, paginationKey});

export const createPaginationReducer = (key, defaultState = {pageNumber: 1, pageSize: 50}) => (state = defaultState, action) => {

  if (action.paginationKey !== key) {
    return state;
  }
  switch (action.type) {
    case SET_PAGE:
      return {...state, pageNumber: action.pageNumber};
    case SET_PAGE_SIZE:
      return {...state, pageSize: action.pageSize};
    case SET_PAGINATION:
      return action.pagination;
    case RESET_PAGINATION:
      return defaultState;
    default:
      return state;
  }
};
